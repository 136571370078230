<template>
  <b-modal
    v-model="value"
    modal-class="autopay-cancellation-modal"
    body-class="px-0"
    size="md"
    :hide-header="true"
    centered
  >
    <div class="header-text px-4">Cancel Autopay Confirmation</div>
    <hr>
    <div class="px-4 mb-3">
      You are about to cancel your autopay for the following services.
    </div>
    <div class="company-services-window mx-3 py-2">
      <div v-for="(company, index) in getCancellations" :key="company.id" class="company-services px-2 w-100">
        <strong>{{ company.name }}</strong>
        <div v-for="service in company.services" :key="service.id" class="w-100 d-flex pt-1 d-flex pl-2">
          <div>{{ service.product.description || service.product.name }}</div>
          <div v-if="getPayableInfo(service)" class="ml-auto d-inline-flex">
            <b-img class="ml-auto" height="18px" :src="getPayableInfo(service).image" />
            <span class="ml-1 text-nowrap"> {{ getPayableInfo(service).number }}</span>
          </div>
        </div>
        <hr v-if="index !== getCancellations.length - 1" class="my-1" />
      </div>
    </div>
    <div class="px-4 mt-3">
      Do you wish to continue?
    </div>
    <template #modal-footer>
      <b-button
        class="secondary-button"
        variant="outline-primary"
        aria-label="No Button"
        @click="close"
      >
        No
      </b-button>
      <b-button
        class="primary-button"
        variant="primary"
        aria-label="Confirm Button"
        @click="confirm"
      >
        Yes
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { getCardLogo } from '@/common/modules/cc'
import { mapGetters } from 'vuex'

export default {
  name: 'AutoPayCancellationModal',
  props: {
    services: {
      type: Array,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('paymentMethods', ['cards', 'automatedClearingHouses']),
    getCancellations() {
      return this.services
        .filter(svc => svc.autopay_payable_id)
        .reduce((acc, service) => {
          const companyServices = acc.find(cs => cs.id === service.company_id)
          if (!companyServices) {
            acc.push({
              ...service.company,
              services: [service],
            })
          }
          else {
            companyServices.services.push(service)
          }
          return acc
        }, [])
    },
  },
  methods: {
    getPayableInfo(service){
      const card = this.cards.find(card => card.id == service.autopay_payable_id)
      const ach = this.automatedClearingHouses.find(ach => ach.id === service.autopay_payable_id)

      if(card)
        return { image: getCardLogo(card.brand), number: `*${card.last4}` }
      if(ach)
        return { image: '/images/automatedClearingHouses/ach.svg', number: `***${ach.bank_account_number.slice(-2)}` }
    },
    confirm(){
      this.$emit('confirm')
    },
    close(){
      this.$emit('input', false)
    },
  },
}
</script>


<style scoped lang="scss">
.autopay-cancellation-modal {

  .secondary-button {
    color: #000864 !important;
    border-color: #000864 !important;
    height: 40px;
    width: 110px;
    border-radius: 4px;

    &:hover {
      background-color: #F1F1F2 !important;
      border-color: #000864 !important;
      color: #000864 !important;
    }
  }

  .primary-button {
    background-color: #000864 !important;
    border-color: #000864 !important;
    color: white;
    height: 40px;
    min-width: 110px;
    padding-left: 0;
    padding-right: 0;
    border-radius: 4px;
  }

  .header-text {
    font-size: 1.5rem;
    font-weight: 400;
  }

  .company-services-window {
    max-height: 400px;
    overflow-y: scroll;
    border: 1px solid #BDBDBD;
    border-radius: 4px;

    div {
      font-size: .85rem;
      font-weight: 350;
    }
  }
}
</style>
