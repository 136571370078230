var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "modal-class": "autopay-cancellation-modal",
        "body-class": "px-0",
        size: "md",
        "hide-header": true,
        centered: "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  staticClass: "secondary-button",
                  attrs: {
                    variant: "outline-primary",
                    "aria-label": "No Button",
                  },
                  on: { click: _vm.close },
                },
                [_vm._v("\n      No\n    ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "primary-button",
                  attrs: { variant: "primary", "aria-label": "Confirm Button" },
                  on: { click: _vm.confirm },
                },
                [_vm._v("\n      Yes\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("div", { staticClass: "header-text px-4" }, [
        _vm._v("Cancel Autopay Confirmation"),
      ]),
      _c("hr"),
      _c("div", { staticClass: "px-4 mb-3" }, [
        _vm._v(
          "\n    You are about to cancel your autopay for the following services.\n  "
        ),
      ]),
      _c(
        "div",
        { staticClass: "company-services-window mx-3 py-2" },
        _vm._l(_vm.getCancellations, function (company, index) {
          return _c(
            "div",
            { key: company.id, staticClass: "company-services px-2 w-100" },
            [
              _c("strong", [_vm._v(_vm._s(company.name))]),
              _vm._l(company.services, function (service) {
                return _c(
                  "div",
                  {
                    key: service.id,
                    staticClass: "w-100 d-flex pt-1 d-flex pl-2",
                  },
                  [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          service.product.description || service.product.name
                        )
                      ),
                    ]),
                    _vm.getPayableInfo(service)
                      ? _c(
                          "div",
                          { staticClass: "ml-auto d-inline-flex" },
                          [
                            _c("b-img", {
                              staticClass: "ml-auto",
                              attrs: {
                                height: "18px",
                                src: _vm.getPayableInfo(service).image,
                              },
                            }),
                            _c("span", { staticClass: "ml-1 text-nowrap" }, [
                              _vm._v(
                                " " + _vm._s(_vm.getPayableInfo(service).number)
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              index !== _vm.getCancellations.length - 1
                ? _c("hr", { staticClass: "my-1" })
                : _vm._e(),
            ],
            2
          )
        }),
        0
      ),
      _c("div", { staticClass: "px-4 mt-3" }, [
        _vm._v("\n    Do you wish to continue?\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }