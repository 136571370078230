
<template>
  <div class="manage-autopay w-100 d-flex flex-column h-100 ">
    <b-row class="m-0">
      <b-col class="mobile-format">
        <div class="title py-2 my-auto">Manage Autopay</div>
      </b-col>
      <b-col cols="12" md="1" />
      <b-col cols="auto" class="mobile-format">
        <div v-if="companyServices.length" class="d-flex w-100 h-100 py-2">
          <b-button
            class="secondary-button ml-auto my-auto"
            variant="outline-primary"
            aria-label="Done Button"
            :disabled="!!loadingMessage"
            @click="goBack"
          >
            Done
          </b-button>
          <b-button
            class="primary-button ml-2 my-auto"
            variant="primary"
            :disabled="submitDisabled"
            aria-label="Save Button"
            @click="save"
          >
            <fa-icon class="mr-1" :icon="['fas', 'save']" size="lg" />
            Save
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <div v-if="!companyServices.length && !loadingMessage" class="mx-auto">
      <h4 class="pb-2">No services selected</h4>
      <b-link
        variant="link"
        aria-label="Go Back Link"
        @click="goBack"
      >
        <fa-icon icon="chevron-left" />
        Back
      </b-link>
    </div>
    <div v-else class="autopay-body d-flex flex-column">
      <b-row class="h-100 w-100 m-0">
        <b-col cols="12" md="5" lg="4" xl="3" class="company-infos mobile-format">
          <div v-if="companyServices.length" class="w-100 d-flex px-2 my-2">
            <strong>Companies</strong>
            <div class="ml-auto pl-2 d-flex">
              <b-checkbox
                v-model="selectEveryChecked"
                :disabled="!!loadingMessage"
                @change="selectEveryServiceChange"
              />
              <div class="small my-auto">Select All</div>
            </div>
          </div>
          <div
            v-for="company in companyServices"
            :key="company.id"
            class="company-services mb-2 p-2 w-100"
          >
            <div class="w-100 d-flex ">
              <strong>{{ company.name }}</strong>
              <div class="ml-auto pl-2 d-flex">
                <b-checkbox
                  v-model="company.selectAllChecked"
                  :value="true"
                  :disabled="!!loadingMessage"
                  @change="selectServicesChanged(company.id, true)"
                />
                <div class="small my-auto">Select All</div>
              </div>
            </div>
            <table class="mt-2 w-100">
              <template v-for="service in company.services">
                <tr :key="service.id + service.product.id">
                  <td>
                    <b-checkbox
                      v-model="selectedServices"
                      :value="service"
                      :disabled="!!loadingMessage"
                      @change="selectServicesChanged(service.company_id, false)"
                    />
                  </td>
                  <td class="pt-1">
                    <div>{{ service.product.description || service.product.name }}</div>
                  </td>
                  <td>
                    <div v-if="getPayableInfo(service)" class="d-inline-flex pt-1 pl-2 w-100">
                      <b-img class="ml-auto" height="18px" :src="getPayableInfo(service).image" />
                      <span class="ml-1 text-nowrap"> {{ getPayableInfo(service).number }}</span>
                    </div>
                  </td>
                </tr>
                <tr :key="service.id + service.product.description">
                  <td />
                  <td>
                    <div>{{ durationText(service) }} <strong>{{ dateTableColumn(service.stop) }}</strong></div>
                  </td>
                  <td class="text-right">
                    {{ service.renewal_price | currency }}
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </b-col>
        <b-col class="mobile-format">
          <payment-method-options
            class="mt-2"
            :is-show-actions="false"
            :is-show-pay-agreement="false"
          />
          <div class="w-100 cancel-row d-flex px-3 py-2">
            <b-form-radio
              v-model="stopAutopaySelected"
              :value="true"
              :disabled="cancelOptionDisabled"
              class="my-auto"
            />
            <span class="my-auto ml-4" :class="cancelOptionDisabled ? 'disabled' : null">Stop Autopay</span>
          </div>
        </b-col>
      </b-row>
    </div>
    <ct-centered-spinner v-if="loadingMessage">
      {{ loadingMessage }}
    </ct-centered-spinner>
    <auto-pay-cancellation-modal
      v-model="showCancellationModal"
      :services="selectedServices"
      @confirm="submit"
    />
  </div>
</template>

<script>

import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
import { mapActions, mapGetters } from 'vuex'
import http from '@/http'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import PaymentMethodOptions from '@/components/Payments/PaymentMethodOptions'
import { formatDateStringFromSeconds } from '@/common/modules/formatters'
import { getCardLogo } from '@/common/modules/cc'
import AutoPayCancellationModal from '@/components/Payments/AutoPay/AutoPayCancellationModal'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

export default {
  name: 'ManageAutopay',
  components: { AutoPayCancellationModal, PaymentMethodOptions, CtCenteredSpinner },
  mixins: [makeToastMixin],
  props: {
    serviceIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      companyServices: [],
      selectedServices: [],
      selectEveryChecked: false,
      loadingMessage: null,
      stopAutopaySelected: false,
      showCancellationModal: false,
    }
  },
  computed: {
    ...mapGetters('services', ['services']),
    ...mapGetters('paymentMethods', [
      'selectedPaymentMethod',
      'cards',
      'automatedClearingHouses',
    ]),
    cancelOptionDisabled() {
      return !this.selectedServices.some(svc => svc.autopay_payable_id)
    },
    submitDisabled() {
      return !this.stopAutopaySelected && !this.selectedPaymentMethod
        || !this.selectedServices.length || !!this.loadingMessage
    },
    autopayServices(){
      return this.services.filter(svc => this.serviceIds.some(sid => svc.id === sid))
    },
  },
  watch: {
    selectedPaymentMethod(value) {
      if(value){
        this.stopAutopaySelected = false
      }
    },
    stopAutopaySelected(value) {
      if(value){
        this.resetPaymentMethod()
      }
    },
  },
  async mounted() {
    await Promise.all([
      this.loadServices(this.serviceIds.filter(sid =>
        this.services.every(svc => svc.id !== sid))),
      this.resetPaymentMethod(),
    ])
  },
  methods: {
    ...mapActions('paymentMethods', ['resetPaymentMethod']),
    ...mapActions('services', ['getAndReloadServices']),
    async loadServices(ids) {
      this.loadingMessage = 'Loading Services'
      const params = { include_company: true }
      await this.getAndReloadServices({ params, ids })

      this.companyServices = this.groupByCompany(this.autopayServices)
      this.selectedServices = [...this.companyServices.flatMap(cs => cs.services)]
      this.selectEveryChecked = true
      this.loadingMessage = null
    },
    selectServicesChanged(companyId, selectAll){
      const thisCompanySvcs = this.companyServices.find(cs => cs.id === companyId)
      const companySelectedServices = this.selectedServices.filter(ss => ss.company_id === companyId)
      if(selectAll){
        const companyFiltered = this.selectedServices.filter(sel => sel.company_id !== companyId)
        this.selectedServices = thisCompanySvcs.selectAllChecked
          ? companyFiltered.concat(thisCompanySvcs.services)
          : companyFiltered
      }
      else {
        thisCompanySvcs.selectAllChecked = thisCompanySvcs.services.length === companySelectedServices.length
      }
      this.selectEveryChecked = this.companyServices.every(cs => cs.selectAllChecked)
    },
    selectEveryServiceChange(){
      this.selectedServices = this.selectEveryChecked
        ? [...this.companyServices.flatMap(cs => cs.services)]
        : []
      this.companyServices.forEach(cs => cs.selectAllChecked = this.selectEveryChecked)
    },
    getPayableInfo(service){
      const card = this.cards.find(card => card.id == service.autopay_payable_id)
      const ach = this.automatedClearingHouses.find(ach => ach.id === service.autopay_payable_id)

      if(card)
        return { image: getCardLogo(card.brand), number: `*${card.last4}` }
      if(ach)
        return { image: '/images/automatedClearingHouses/ach.svg', number: `***${ach.bank_account_number.slice(-2)}` }
    },
    durationText(service) {
      if (service.status === 'trial-active') {
        return 'Converts to Active'
      } else {
        return service.autopay_payable_id ? 'Renews' : 'Ends on'
      }
    },
    dateTableColumn(date) {
      return date === 'Invalid Date' ? '---' : formatDateStringFromSeconds(date)
    },
    groupByCompany(services) {
      return services.reduce((acc, service) => {
        const existingEntry = acc.find(ac => ac.id === service.company_id)
        if(existingEntry) {
          existingEntry.services.push(service)
        } else {
          acc.push({
            ...service.company,
            services: [service],
            selectAllChecked: true,
          })
        }
        return acc
      }, [])
    },
    save() {
      if(this.stopAutopaySelected){
        this.showCancellationModal = true
      }else{
        this.submit()
      }
    },
    async submit(){
      this.showCancellationModal = false
      this.loadingMessage = 'Updating Payment Methods'
      const updatedServices = [...this.selectedServices]
      const promises = updatedServices.map(service => http.post(`client/companies/${service.company_id}/services/${service.id}/set_autopay_id`,
        {
          ...service,
          autopay_payable_id: this.selectedPaymentMethod?.id,
        })
      )
      try {
        await Promise.all(promises)
        const autoPayServicesChanged = this.selectedServices
          .filter(updated => this.services.some(svc =>
          {
            if (updated.id !== svc.id) return false
            return this.selectedPaymentMethod?.id ? !svc.autopay_payable_id : svc.autopay_payable_id
          }))
          .map(svc => svc.id)
        this.logAutopaySetupInteraction(autoPayServicesChanged, !!this.selectedPaymentMethod?.id)
        await this.loadServices(this.selectedServices.map(ss => ss.id))
        this.successToast('Success', 'Autopay updated successfully')
        this.resetSelections()
      } catch {
        this.errorToast('Error', 'Error updating payment methods')
      } finally {
        this.loadingMessage = null
      }
    },
    resetSelections(){
      this.resetPaymentMethod()
      this.stopAutopaySelected = false
    },
    goBack(){
      this.resetSelections()
      this.$router.push('/services')
    },
    logAutopaySetupInteraction(service_ids, added) {
      if(!service_ids || !service_ids.length) return
      const addedRemoved = added ? 'added' : 'removed'
      const interaction = {
          name: 'manage-autopay-page',
          action: `autopay-services-${addedRemoved}`,
          service_ids,
        }
      createOrFindClientInteractionLog({
        category: 'autopay-interaction',
        subCategory: 'manage-autopay',
        interaction,
        incompleteLogFromToday: true,
      }).catch() // suppress any api errors
    },
  },
}
</script>


<style scoped lang="scss">

@media only screen and (max-width: 767px) {
  .mobile-format {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
    min-height: auto !important;
    background-color: white !important;
  }
}

.manage-autopay {
  min-height: 90vh;

  .secondary-button {
    color: #000864 !important;
    border-color: #000864 !important;
    height: 40px;
    width: 110px;
    border-radius: 4px;

    &:hover {
      background-color: #F1F1F2 !important;
      border-color: #000864 !important;
      color: #000864 !important;
    }
  }

  .primary-button {
    background-color: #000864 !important;
    border-color: #000864 !important;
    color: white;
    height: 40px;
    min-width: 110px;
    padding-left: 0;
    padding-right: 0;
    border-radius: 4px;
  }

  .ct-centered-spinner-component {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
  }

  hr {
    border-top: 1px solid #BDBDBD;
    margin: 0;
  }

  .autopay-body {
    min-height: 100%;
    max-width: 1500px !important;

    .company-infos {
      background-color: #F1F1F2;
      min-height: 100%;
    }

    .company-services {
      background-color: white;
      border: 1px solid #BDBDBD;
      border-radius: 4px;


      tr{
        &:not(:nth-child(even)){
          border-top: 1px solid #BDBDBD;
        }
        &:not(:nth-child(odd)):not(:last-child) {
          border-bottom: 1px solid #BDBDBD;
        }
      }


      th {
        font-weight: 600;
        font-size: .8rem;
      }
      td {
        font-size: .8rem;
        font-weight: 350;
        vertical-align: top;
      }
    }

    .cancel-row {
      background-color: #F4F4F5 !important;
      font-size: .85rem;
      font-weight: 700;
      border-top: 1px solid #DCDDDD;
      border-bottom: 1px solid #DCDDDD;

      .disabled {
        color: #BDBDBD;
      }
    }
  }
}
</style>
